<template>
  <v-container fluid class="pt-0" style="height: 100vh;">
    <v-dialog max-width="80%" v-model="dialog">
      <v-card>
        <v-card-text style="font-weight: 500; font-size: 1.3rem" class="grey darken-2 py-4 white--text">
          Помилки при завантажені оплат... Відсутні абоненти
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pt-3 pb-4">
          <div class="error-row" style="display: flex; padding: 0 2px; margin-bottom: 4px; background-color: #f1f1f1" v-for="(item, idx) in errors" :key="idx">
            <div style="flex: 0 0 60px; padding: 5px 2px 5px 2px; text-align: center; background-color: #d7d7d7;">{{ idx + 1 }}</div>
            <div>
              <p class="mb-0"
                  style="font-size: .95rem; font-style: oblique; padding: 5px 2px 5px 10px">
                {{ item }}
              </p>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12">
        <v-card max-width="700" class="mx-auto" elevation="0">
          <v-tabs class="custom-tabs" color="success">
            <v-tab>Основне</v-tab>
            <v-tab>Історія</v-tab>

            <v-tab-item class="pa-2" style="background-color: #f5f5f5 !important;">
              <v-card elevation="2">
                <v-card-title style="font-size: 1.2rem; text-transform: uppercase">
                  Здача бази для Privat24/ПриватБанк
                </v-card-title>
                <v-card-text>
                  <v-alert :color="alert_color" text class="mb-4">
                    {{ alert_text }}
                  </v-alert>
                </v-card-text>
                <v-card-text class="pt-0">
                  <v-row class="dense-wrapper">
                    <v-col cols="12" md="6">
                      <date-component monthly
                                      v-model="month"
                                      label="Місяць здачі бази"
                                      :class_="!month ? 'req-star' : ''"
                                      req
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox color="secondary"
                                  hide-details
                                  v-model="short_form"
                                  class="mt-2"
                                  label="Скорочена форма"/>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox color="secondary"
                                  hide-details
                                  v-model="start_debt_minus_pay"
                                  class="mt-0"
                                  label="Борг на поч.за мінусом оплати"/>
                    </v-col>
                  </v-row>

                </v-card-text>
                <v-card-text class="pt-0">
                  <v-btn block class="success--text mb-3" @click="genPrivat" :disabled="!month" :loading="loading">
                    {{ base_send ? 'База здана (клік для повторної здачі)' : 'Здати базу' }}
                  </v-btn>
                  <v-btn block class="error--text" @click.stop="dialog = true" :disabled="!errors.length">Перeглянути помилки</v-btn>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item class="grey lighten-5 pa-2" style="background-color: #f5f5f5 !important;">
              <v-card min-height="272">
                <v-card-text>
                  <div v-for="(item, idx) in items" :key="idx"
                      class="d-flex py-1 px-2 grey lighten-4 mb-2">
                    <div style="flex: 1 1 30%">
                      <div class="col-name">Місяць</div>
                      <div class="col-value">{{ item.month | formatDate('MMMM YYYY') }}</div>
                    </div>
                    <div style="flex: 1 1 40%">
                      <div class="col-name">Дата</div>
                      <div class="col-value">
                        {{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}
                      </div>
                    </div>
                    <div style="flex: 1 1 30%">
                      <div class="col-name">Користувач</div>
                      <div class="col-value">
                        {{ item.owner_name }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import epsAPI from "@/utils/axios/eps";
import {ALERT_SHOW} from "@/store/actions/alert";
import organizationAPI from "@/utils/axios/organization"
import {mapGetters} from "vuex";

export default {
  name: "PrivatPutFile",
  data() {
    return {
      alert_text: 'База здається для відображення заборгованості мешканців у Privat24. Рекомендована дата здачі - 5 днів після закінчення розрахункового місяця.',
      alert_color: 'grey darken-2',
      month: null,
      short_form: false,
      start_debt_minus_pay: false,
      errors: [],
      dialog: false,
      base_send: false,
      items: [],
      firstTime: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      organization: 'getCurrentOrganization',
      current_month: 'getCurrentMonth'
    })
  },
  methods: {
    genPrivat() {
      if (!this.month) {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць має бути заповнений', color: 'error'})
        return
      }
      const payload = {
        month: this.month,
        short_form: this.short_form,
        start_debt_minus_pay: this.start_debt_minus_pay
      }
      this.loading = true
      epsAPI.generate_privat24_file(payload)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            const file_name = `${this.organization.code||'privat24'}.dbf`
            link.href = url;
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
            this.fetch_items()
            this.loading = false
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            this.loading = false
          })
    },
    fetch_items() {
      organizationAPI.get_bases_send({base_type: 'privat24'})
          .then(response => response.data)
          .then(data => {
            this.items = data
            const current = this.items.find(item => item.current)
            if (current) {
              this.base_send = true
              this.alert_text = 'Будьте уважні! Цього місяця Ви вже здавали базу. Для повторної здачі виконати команду "База здана (клік для повторної здачі)"'
            }
            if (this.firstTime === null) {
              this.month = this.current_month
              this.firstTime = false
            }
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
            if (this.firstTime === null) {
              this.month = this.current_month
              this.firstTime = false
            }
          })
    }
  },
  created() {
    this.fetch_items()
  }
}
</script>

<style scoped lang="scss">
  .custom-tabs {
    :deep(.v-window.v-item-group.theme--light) {
      background-color: transparent;
    }

    :deep(div[role="tablist"]) {
      background-color: #f5f5f5 !important;
    }
  }

  .col-name {
    font-size: .76rem;
    line-height: .72rem;
    color: #2f8232;
    margin-top: 3px;
  }
  .col-value {
    font-size: .82rem;
    font-weight: 500;
    color: #5e5e5e;
  }
</style>